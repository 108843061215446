import { useEffect, useState } from 'react';

import { ApolloClient, useApolloClient } from '@apollo/client';
import { GET_ORGANIZATION_QUERY } from 'apollo/organization-api/organization';
import { GetOrganization } from 'apollo/organization-api/types/GetOrganization';
import { User, useUser } from 'context/AuthContext';
import { getI18n } from 'react-i18next';
import { fetchApi } from 'utils/fetchApi';
import { currentTenant } from 'utils/multitenant';

// 👇 The Look ID for the Learning
const LOOKER_LEARNING_BY_LANGUAGE = {
  EN: 297,
  FR: 301,
  DE: 300,
  IT: 302,
  ES: 303,
  PT: 304,
};
// 👇 The default Look ID for the Learning
let LOOKER_LEARNING_REPORT_ID = LOOKER_LEARNING_BY_LANGUAGE['EN'];

type OrgData = {
  id: string;
  name: string | null;
  logo: string | null;
};

export type LearningState = {
  org: {
    loading: boolean;
    data: OrgData | null;
    error?: string;
  };
  looker: {
    loading: boolean;
    url: string | null;
    error?: string;
  };
};
const initialState: LearningState = {
  org: {
    loading: false,
    data: null,
  },
  looker: {
    loading: false,
    url: null,
  },
};

/**
 * Handle business logic related to Learning Paths
 *
 * @returns LearningState
 */
export function useLearning() {
  const user = useUser();
  const { language } = getI18n();
  const apolloClient = useApolloClient();
  const [org, setOrg] = useState<LearningState['org']>(initialState.org);
  const [looker, setLooker] = useState<LearningState['looker']>(initialState.looker);
  const LANG_KEY = language.split('_')[0].toUpperCase();

  if (LOOKER_LEARNING_BY_LANGUAGE[LANG_KEY as keyof typeof LOOKER_LEARNING_BY_LANGUAGE]) {
    LOOKER_LEARNING_REPORT_ID = LOOKER_LEARNING_BY_LANGUAGE[LANG_KEY as keyof typeof LOOKER_LEARNING_BY_LANGUAGE];
  }

  useEffect(() => {
    let stale = false;
    async function fetchOrganization(client: ApolloClient<object>, user: User) {
      setOrg({
        loading: true,
        data: null,
      });
      const {
        data: { getOrganization },
      } = await client.query<GetOrganization>({
        query: GET_ORGANIZATION_QUERY,
        variables: {
          organizationId: user.organizationId,
        },
        context: { clientName: currentTenant(user.tenantId) },
      });
      if (!stale) {
        if (!getOrganization) {
          setOrg({
            loading: false,
            data: null,
            error: 'org not found',
          });
        } else {
          const { id, name, logo } = getOrganization;
          setOrg({
            loading: false,
            data: { id, name, logo },
          });
        }
      }
    }
    fetchOrganization(apolloClient, user);

    return () => {
      stale = true;
    };
  }, [apolloClient, user]);

  useEffect(() => {
    let stale = false;
    async function fetchLookerURL(language: string) {
      setLooker({
        loading: true,
        url: null,
      });
      try {
        const result = await fetchApi(`/api/lookerDashboardUrlById?lang=${language}&id=${LOOKER_LEARNING_REPORT_ID}`, {
          method: 'GET',
        });
        const data = (await result.json()) as { url: string };
        if (!stale) {
          setLooker({
            loading: false,
            url: data.url,
          });
        }
      } catch (error) {
        console.error(`Failed to fetch Looker URL: ${error}`);
        if (!stale) {
          setLooker({
            loading: false,
            url: null,
            error: String(error),
          });
        }
      }
    }
    fetchLookerURL(language);

    return () => {
      stale = true;
    };
  }, [language]);

  return { org, looker };
}
