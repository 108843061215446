import { getBackgroundColor } from '@coachhubio/nova-colors';
import { Column } from '@coachhubio/nova-flex';
import { marginBottom, padding, paddingX } from '@coachhubio/nova-spaces';
import { LinearProgress } from '@coachhubio/ui-components';
import { Title } from 'components/Title';
import styled from 'styled-components';

import { LookerDashboardError } from '../components/LookerDashboardError';
import { Iframe } from '../Iframe';
import { useStyles } from '../LookerDashboard';
import { LearningState, useLearning } from './useLearning';

const IFramePadding = styled.div`
  ${paddingX('base')}
  @media screen and (min-width: 1060px) {
    ${paddingX('m')}
  }
  @media screen and (min-width: 1490px) {
    ${paddingX('5xl')}
  }
`;

const IFrameTitle = styled(Title)`
  ${paddingX('base')}
  justify-content: space-between;
  height: 65px;
  @media screen and (min-width: 1060px) {
    ${paddingX('m')}
  }
  @media screen and (min-width: 1490px) {
    ${paddingX('5xl')}
  }
`;

const Wrapper = styled(Column)`
  min-height: 100vh;
  ${getBackgroundColor('neutral')}
  overflow-y: hidden;
  ${padding('m')}
`;

const ContainerScaled = styled.div`
  ${marginBottom('none')}
  height: 100%;
`;

export function LookerLearningDashboard() {
  const classes = useStyles({});
  const state = useLearning();

  function renderIFrame(looker: LearningState['looker']): JSX.Element {
    if (looker.loading) {
      return (
        <IFramePadding>
          <LinearProgress />
        </IFramePadding>
      );
    }
    if (looker.error) {
      return <LookerDashboardError />;
    }
    return <Iframe url={looker.url!} />;
  }

  return (
    <Wrapper>
      <IFrameTitle data-testid="head-section">
        {state.org.loading ? null : (
          <>
            <span data-hj-suppress>{state.org.data?.name ?? 'No Name'}</span>
            {state.org.data?.logo ? (
              <img
                src={state.org.data.logo}
                alt={`${state.org.data.name} logo`}
                className={classes.logo}
                data-hj-suppress
              />
            ) : null}
          </>
        )}
      </IFrameTitle>
      <ContainerScaled>{renderIFrame(state.looker)}</ContainerScaled>
    </Wrapper>
  );
}
