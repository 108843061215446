import { getBackgroundColor } from '@coachhubio/nova-colors';
import { Divider } from '@coachhubio/nova-divider';
import { Column } from '@coachhubio/nova-flex';
import { GraduationIcon, RobotIcon, SitemapIcon, StarIcon, UsersIcon } from '@coachhubio/nova-icon';
import { SplitLayout } from '@coachhubio/nova-layout';
import { Sidebar, SidebarItem } from '@coachhubio/nova-sidebar';
import { Heading } from '@coachhubio/nova-text';
import Footer from 'components/Footer';
import { Link } from 'components/Link';
import { LookerProvider } from 'context/LookerContext';
import { useFlags } from 'hooks/useFlags';
import Head from 'next/head';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { LookerCoDevHubsDashboard } from './CoDevHubs/LookerCoDevHubsDashboard';
import { LookerCompanionDashboard } from './Companion/LookerCompanionDashboard';
import { LookerEngagementDashboard } from './Engagement/LookerEngagementDashboard';
import LookerImpactDashboard from './Impact/LookerImpactDashboard';
import { LookerInsightsPlusDashboard } from './InsightsPlus/LookerInsightsPlusDashboard';
import { LookerLearningDashboard } from './Learning/LookerLearningDashboard';
import { LookerDashboard } from './LookerDashboard';
import { LookerMRFDashboard } from './MultiRaterFeedback/LookerMRFDashboard';
import { LookerOverviewDashboard } from './Overview/LookerOverviewDashboard';
import { LookerSatisfactionDashboard } from './Satisfaction/LookerSatisfactionDashboard';
import SectionsProps from './utils/dashboardSideBar';

const DashboardWrapper = styled.div<{ $height: string }>`
  display: flex;
  height: ${({ $height }) => $height};
`;

const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

const StyledColumn = styled(Column)`
  flex: 1;

  ${getBackgroundColor('background-color-default')}
`;

export function LookerDashboardSideBar({ currentSectionIndex }: { currentSectionIndex: number }) {
  const { sectionIds, sectionHeights, sectionNames, sectionHrefs, sectionIcons } = SectionsProps;

  const { t } = useTranslation('Dashboard');
  const { showDashboardSections, showMultiRaterFeedbackPage, showInsightsPlus, showCoDevHubsPage, showLearningPage, showCompanionPage } =
    useFlags();

  const wrapperHeight = showDashboardSections ? '100%' : `${sectionHeights[currentSectionIndex]}px`;

  let menu = (
    <>
      {sectionIds.map((dashId, index) => (
        <StyledLink href={sectionHrefs[index]} data-testid={sectionNames[index]} key={`${dashId}-${index}`}>
          <SidebarItem active={dashId === sectionIds[currentSectionIndex]} Icon={sectionIcons[index]}>
            {t(`section.${sectionNames[index]}`)}
          </SidebarItem>
        </StyledLink>
      ))}
      {showMultiRaterFeedbackPage || showInsightsPlus || showCoDevHubsPage || showLearningPage || showCompanionPage ? (
        <>
          <Divider marginBottom="m" />
          <Heading marginStart="s" marginBottom="s" size="xs" modifier="bold" color="text30">
            {t('section.additional-insights')}
          </Heading>
        </>
      ) : null}
      {showMultiRaterFeedbackPage ? (
        <Link href={'/multirater-feedback'} data-testid={'multirater-feedback'} key={`multirating-feedback`}>
          <SidebarItem active={4 === currentSectionIndex} Icon={SitemapIcon}>
            {t(`section.multirater-feedback`)}
          </SidebarItem>
        </Link>
      ) : null}
      {showInsightsPlus ? (
        <Link href={'/insightsplus'} data-testid={'insightsplus'} key={`${-1}-${5}`}>
          <SidebarItem active={5 === currentSectionIndex} Icon={StarIcon}>
            {t(`section.insightsplus`)}
          </SidebarItem>
        </Link>
      ) : null}
      {showCoDevHubsPage ? (
        <Link href={'/codevhubs'} data-testid={'codevhubs'} key={`${-1}-${6}`}>
          <SidebarItem active={6 === currentSectionIndex} Icon={UsersIcon}>
            {t(`section.codevhubs`)}
          </SidebarItem>
        </Link>
      ) : null}
      {showLearningPage ? (
        <Link href={'/learning'} data-testid={'learning'} key={`${-1}-${7}`}>
          <SidebarItem active={7 === currentSectionIndex} Icon={GraduationIcon}>
            {t(`section.learning`)}
          </SidebarItem>
        </Link>
      ) : null}
      {showCompanionPage ? (
        <Link href={'/companion'} data-testid={'companion'} key={`${-1}-${8}`}>
          <SidebarItem active={8 === currentSectionIndex} Icon={RobotIcon}>
            {t(`section.companion`)}
          </SidebarItem>
        </Link>
      ) : null}
    </>
  );

  return (
    <DashboardWrapper $height={wrapperHeight}>
      <Head>
        <title>{t('title')} | Coachhub Company Dashboard</title>
      </Head>

      {showDashboardSections ? (
        <LookerProvider>
          <SplitLayout sideContent={<Sidebar menuItems={menu} />}>
            <StyledColumn>
              {(() => {
                switch (sectionNames[currentSectionIndex]) {
                  case sectionNames[0]:
                    return <LookerOverviewDashboard />;
                  case sectionNames[1]:
                    return <LookerEngagementDashboard />;
                  case sectionNames[2]:
                    return <LookerImpactDashboard />;
                  case sectionNames[3]:
                    return <LookerSatisfactionDashboard />;
                  case 'multirater-feedback':
                    return showMultiRaterFeedbackPage ? <LookerMRFDashboard /> : null;
                  case 'insightsplus':
                    return <>{showInsightsPlus ? <LookerInsightsPlusDashboard /> : <></>}</>;
                  case 'codevhubs':
                    return showCoDevHubsPage ? <LookerCoDevHubsDashboard /> : null;
                  case 'learning':
                    return showLearningPage ? <LookerLearningDashboard /> : null;
                  case 'companion':
                    return showCompanionPage ? <LookerCompanionDashboard /> : null;
                  default:
                    return (
                      <LookerDashboard
                        dashId={sectionIds[currentSectionIndex]}
                        iframeHeight={sectionHeights[currentSectionIndex]}
                      />
                    );
                }
              })()}

              <Footer />
            </StyledColumn>
          </SplitLayout>
        </LookerProvider>
      ) : (
        <StyledColumn>
          <LookerDashboard dashId={0} />
          <Footer />
        </StyledColumn>
      )}
    </DashboardWrapper>
  );
}
